.dinner-menu-container {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 20vw;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    z-index: 3;
    font-family: "baskerville";
    font-style: italic;
    color: #fff;
    opacity: 0;
}

.dinner-menu-container ul {
    list-style: none;
    padding: 0;
    margin-left: 1rem;
}

.dinner-menu-container ul h2,
.dinner-menu-container ul h3 {
    font-size: 1.2em;
    font-weight: lighter;
    margin: 0;
    cursor: pointer;
}

.dinner-menu-container ul a {
    color: #fff;
    text-decoration: none;
    -webkit-transition: all .1s ease;
    -moz-transition: all .1s ease;
    -ms-transition: all .1s ease;
    transition: all .1s ease;
}

.dinner-menu-container ul a:hover {
    text-shadow: rgb(168, 238, 246) 0px 0px 10px;
    
}

.dinner-menu-container ul h1 {
    font-size: 1.5em;
    margin: 0;
    -webkit-transition: all .1s ease;
    -moz-transition: all .1s ease;
    -ms-transition: all .1s ease;
    transition: all .1s ease;
}

.dinner-menu-container ul h2:hover {
    text-shadow: rgb(168, 238, 246) 0px 0px 10px;
}

.dinner-menu-container ul h3:hover {
    text-shadow: rgb(168, 238, 246) 0px 0px 10px;
}

.dinner-menu-container .navigation {
    position: relative;
    z-index: 999;
    overflow-y: auto;
}

.dinner-menu-container .navigation .item-description {
    display: none;
}

.nav-toggle {
    margin-bottom: 2rem;
}

@media only screen and (min-width: 320px) and (max-width: 690px) {
    .dinner-menu-container {
        margin-left: 0;
        justify-content: flex-start;
    }
    
    .dinner-menu-container .navigation {
        width: 100vw;
    }
}