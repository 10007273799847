#video-blur-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    height: 100%;
    width: 100%;
    background: rgb(249,181,180);
    background: linear-gradient(180deg, rgba(249,181,180,1) 0%, rgba(30,38,84,1) 90%); 
    opacity: 0.8;
}

video {
    margin-top: -15rem;
    margin-left: -15rem;
    filter: blur(10px);
    transform: scale(1.3);
}

#home-page-video-container {
    position: fixed;
    top: 0;
    left: 0;
    height: auto;
}

#home-page-video-mobile {
    display: none;
}