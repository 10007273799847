#home-page .home-page-copy-content {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 20vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    z-index: 3;
    font-family: "baskerville";
    font-style: italic;
    color: #fff;
    opacity: 0;
}

#home-page .home-page-copy-content ul {
    list-style: none;
    padding: 0;
    margin-left: 1rem;
}

#home-page .home-page-copy-content ul h1,
#home-page .home-page-copy-content ul h2,
#home-page .home-page-copy-content ul h3 {
    font-size: 1.5em;
    margin: 0;
}

#home-page .home-page-copy-content ul a {
    color: #fff;
    text-decoration: none;
    -webkit-transition: all .1s ease;
    -moz-transition: all .1s ease;
    -ms-transition: all .1s ease;
    transition: all .1s ease;
}

#home-page .home-page-copy-content ul a:hover {
    text-shadow: rgb(168, 238, 246) 0px 0px 10px;
}

#home-page .home-page-copy-content ul h1 {
    -webkit-transition: all .1s ease;
    -moz-transition: all .1s ease;
    -ms-transition: all .1s ease;
    transition: all .1s ease;
    cursor: pointer;
}

#home-page .home-page-copy-content ul h1:hover {
    text-shadow: rgb(168, 238, 246) 0px 0px 10px;
}

#home-page .home-page-copy-content .header h1 {
    color: #ED2124;
    font-family: "painter";
    text-transform: uppercase;
    font-size: 10em;
    -webkit-text-stroke: 5px #ED2124;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
}

#home-page .home-page-copy-content .reservation-options {
    margin-bottom: 1rem;
}

#home-page .home-page-copy-content .reservation-options li {
    font-size: 1.5em;
}

#home-page .home-page-copy-content .reservation-options li:hover {
    text-shadow: rgb(168, 238, 246) 0px 0px 10px;
    cursor: pointer;
}

#home-page .home-page-copy-content .nav-toggle {
    margin-bottom: 0 ;
}

@media only screen and (min-width: 320px) and (max-width: 690px) {
    #home-page .home-page-copy-content {
        margin-left: 0;
        justify-content: flex-start;
    }
}