#popups-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99;
}

#popups-container img {
    width: 20vw;
}

.pop-up-image {
    position: absolute;
}