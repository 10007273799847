@font-face {
    font-family: "baskerville";
    src: url(./Assets/Fonts/Baskerville-Regular.otf);
}

body, html {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
    background: rgb(249,181,180);
    background: linear-gradient(180deg, rgba(249,181,180,1) 0%, rgba(30,38,84,1) 90%); 
}