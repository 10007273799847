@font-face {
    font-family: "baskerville";
    src: url(/static/media/Baskerville-Regular.2c79a286.otf);
}

body, html {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
    background: rgb(249,181,180);
    background: linear-gradient(180deg, rgba(249,181,180,1) 0%, rgba(30,38,84,1) 90%); 
}
#video-blur-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    height: 100%;
    width: 100%;
    background: rgb(249,181,180);
    background: linear-gradient(180deg, rgba(249,181,180,1) 0%, rgba(30,38,84,1) 90%); 
    opacity: 0.8;
}

video {
    margin-top: -15rem;
    margin-left: -15rem;
    -webkit-filter: blur(10px);
            filter: blur(10px);
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}

#home-page-video-container {
    position: fixed;
    top: 0;
    left: 0;
    height: auto;
}

#home-page-video-mobile {
    display: none;
}
#webgl-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    opacity: 0;
}
#home-page .home-page-copy-content {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 20vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    z-index: 3;
    font-family: "baskerville";
    font-style: italic;
    color: #fff;
    opacity: 0;
}

#home-page .home-page-copy-content ul {
    list-style: none;
    padding: 0;
    margin-left: 1rem;
}

#home-page .home-page-copy-content ul h1,
#home-page .home-page-copy-content ul h2,
#home-page .home-page-copy-content ul h3 {
    font-size: 1.5em;
    margin: 0;
}

#home-page .home-page-copy-content ul a {
    color: #fff;
    text-decoration: none;
    transition: all .1s ease;
}

#home-page .home-page-copy-content ul a:hover {
    text-shadow: rgb(168, 238, 246) 0px 0px 10px;
}

#home-page .home-page-copy-content ul h1 {
    transition: all .1s ease;
    cursor: pointer;
}

#home-page .home-page-copy-content ul h1:hover {
    text-shadow: rgb(168, 238, 246) 0px 0px 10px;
}

#home-page .home-page-copy-content .header h1 {
    color: #ED2124;
    font-family: "painter";
    text-transform: uppercase;
    font-size: 10em;
    -webkit-text-stroke: 5px #ED2124;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
}

#home-page .home-page-copy-content .reservation-options {
    margin-bottom: 1rem;
}

#home-page .home-page-copy-content .reservation-options li {
    font-size: 1.5em;
}

#home-page .home-page-copy-content .reservation-options li:hover {
    text-shadow: rgb(168, 238, 246) 0px 0px 10px;
    cursor: pointer;
}

#home-page .home-page-copy-content .nav-toggle {
    margin-bottom: 0 ;
}

@media only screen and (min-width: 320px) and (max-width: 690px) {
    #home-page .home-page-copy-content {
        margin-left: 0;
        justify-content: flex-start;
    }
}
.logo-container {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(50px);
            backdrop-filter: blur(50px);
    z-index: 9999;
    align-items: start;
    display: flex;
    height: 100vh;
    justify-content: center;
    opacity: 0;
    padding-top: 10vh;
    width: 100%;
}

.logo-container .logo:first-child {
    position: absolute;
    z-index: 1;
}

.logo-container .logo:nth-child(2) {
    position: absolute;
    z-index: 2;
}

.logo-container img {
    width: 20vw;
}

.logo-container h1 {
    color: #fff;
    font-family: "baskerville";
    font-size: xxx-large;
    font-style: italic;
    margin-top: 60vh;
    text-transform: lowercase;
}

@-webkit-keyframes loading {
    0% { opacity: 0; }
    33.33% { opacity: 0; }
    66.67% { opacity: 1; }
    100% { opacity: 0; }
  }

@keyframes loading {
    0% { opacity: 0; }
    33.33% { opacity: 0; }
    66.67% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  .loading-dots {
    opacity: 0;
    -webkit-animation: loading 1.5s infinite;
            animation: loading 1.5s infinite;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
  }
  
  .loading-dots:nth-child(1) {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
  }
  
  .loading-dots:nth-child(2) {
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }
  
  .loading-dots:nth-child(3) {
    -webkit-animation-delay: 0.9s;
            animation-delay: 0.9s;
  }

  @media only screen and (min-width: 375px) and (max-width: 768px) {
    .logo-container h1 {
        position: absolute;
        top: 1rem;
        left: 1rem;
        color: #fff;
        font-family: "baskerville";
        font-size: xxx-large;
        font-style: italic;
        margin-top: 0;
        text-transform: lowercase;
    }

    .logo-container .logo:first-child {
        position: absolute;
        bottom: 10vh;
        right: 10vw;
        margin-right: 1rem;
        margin-bottom: 5rem;
        z-index: 1;
    }
    
    .logo-container .logo:nth-child(2) {
        position: absolute;
        bottom: 11vh;
        right: 10vw;
        margin-right: 1rem;
        margin-bottom: 5rem;
        z-index: 2;
    }
}
.dinner-menu-container {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 20vw;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    z-index: 3;
    font-family: "baskerville";
    font-style: italic;
    color: #fff;
    opacity: 0;
}

.dinner-menu-container ul {
    list-style: none;
    padding: 0;
    margin-left: 1rem;
}

.dinner-menu-container ul h2,
.dinner-menu-container ul h3 {
    font-size: 1.2em;
    font-weight: lighter;
    margin: 0;
    cursor: pointer;
}

.dinner-menu-container ul a {
    color: #fff;
    text-decoration: none;
    transition: all .1s ease;
}

.dinner-menu-container ul a:hover {
    text-shadow: rgb(168, 238, 246) 0px 0px 10px;
    
}

.dinner-menu-container ul h1 {
    font-size: 1.5em;
    margin: 0;
    transition: all .1s ease;
}

.dinner-menu-container ul h2:hover {
    text-shadow: rgb(168, 238, 246) 0px 0px 10px;
}

.dinner-menu-container ul h3:hover {
    text-shadow: rgb(168, 238, 246) 0px 0px 10px;
}

.dinner-menu-container .navigation {
    position: relative;
    z-index: 999;
    overflow-y: auto;
}

.dinner-menu-container .navigation .item-description {
    display: none;
}

.nav-toggle {
    margin-bottom: 2rem;
}

@media only screen and (min-width: 320px) and (max-width: 690px) {
    .dinner-menu-container {
        margin-left: 0;
        justify-content: flex-start;
    }
    
    .dinner-menu-container .navigation {
        width: 100vw;
    }
}
#popups-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99;
}

#popups-container img {
    width: 20vw;
}

.pop-up-image {
    position: absolute;
}
.brunch-menu-container {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 20vw;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    z-index: 3;
    font-family: "baskerville";
    font-style: italic;
    color: #fff;
    opacity: 0;
}

.brunch-menu-container ul {
    list-style: none;
    padding: 0;
    margin-left: 1rem;
}

.brunch-menu-container ul h2,
.brunch-menu-container ul h3 {
    font-size: 1.2em;
    font-weight: lighter;
    margin: 0;
    cursor: pointer;
}

.brunch-menu-container ul a {
    color: #fff;
    text-decoration: none;
    transition: all .1s ease;
}

.brunch-menu-container ul a:hover {
    text-shadow: rgb(168, 238, 246) 0px 0px 10px;
    
}

.brunch-menu-container ul h1 {
    font-size: 1.5em;
    margin: 0;
    transition: all .1s ease;
}

.brunch-menu-container ul h2:hover {
    text-shadow: rgb(168, 238, 246) 0px 0px 10px;
}

.brunch-menu-container ul h3:hover {
    text-shadow: rgb(168, 238, 246) 0px 0px 10px;
}

.brunch-menu-container .navigation {
    position: relative;
    z-index: 999;
    overflow-y: auto;
}

.brunch-menu-container .navigation .item-description {
    display: none;
}

.nav-toggle {
    margin-bottom: 2rem;
    cursor: pointer;
}

@media only screen and (min-width: 320px) and (max-width: 690px) {
    .brunch-menu-container {
        margin-left: 0;
        justify-content: flex-start;
    }

    .brunch-menu-container .navigation {
        width: 100vw;
    }
}
